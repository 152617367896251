/* App
-------------------------- */
$-centered-component-max-width: 950px;

/* Colors
-------------------------- */
$-color-primary-30: #d0c3d9;
$-color-primary-40: #ead0fd;
$-color-primary-50: #dad3ea;
$-color-primary-60: #ba9fd0;
$-color-primary-80: #a37fc0;
$-color-primary-100: #8c5fb0;
$-color-primary-120: #704c8d;
$-color-secondary-30: #f6d09e;
$-color-secondary-60: #fac383;
$-color-secondary-80: #ffa135;
$-color-secondary-100: #ff8b05;
$-color-secondary-120: #cc6f04;
$-color-blue-30: #b4cbe0;
$-color-blue-60: #7caad5;
$-color-blue-80: #5491cb;
$-color-blue-100: #2d7dc8;
$-color-blue-120: #2464a0;
$-color-indigo-30: #b4dddb;
$-color-indigo-60: #7bcdcc;
$-color-indigo-80: #53c1bd;
$-color-indigo-100: #2cbbb6;
$-color-indigo-120: #239692;
$-color-green-30: #cde2be;
$-color-green-60: #afd78e;
$-color-green-80: #98cd6c;
$-color-green-100: #81c64b;
$-color-green-120: #6aa33e;
$-color-yellow-30: #f5f0b7;
$-color-yellow-60: #f1e476;
$-color-yellow-80: #f2e14d;
$-color-yellow-100: #f8e323;
$-color-yellow-120: #e8d414;
$-color-red-30: #e8c7c0;
$-color-red-60: #e4a293;
$-color-red-80: #e28873;
$-color-red-100: #e37055;
$-color-red-120: #b65a44;
$-footer-bg-color: #7668ec;
$-header-border-color: rgb(0 0 0 / 16%);
$-color-primary: hsl(273deg 34% 53%);
$-color-primary-light: hsl(273deg 33% 88%);
$-color-secondary: hsl(32deg 100% 60%);
$-color-secondary-light: hsl(34deg 83% 79%);
$-color-primary-hover: hsl(204deg 97% 80%);
$-color-light-purple: #e8e4f8;
$-color-light-pink: #f9f9fc;
$-color-input-filled: #e8f0fe;
$-color-primary-gradient: linear-gradient(to left, $-color-primary, #7f3beb);
$-color-white: #fff;
$-color-black: hsl(0deg 2% 8%);
$-color-grey: #e0e2d8;
$-color-neutral-grey: #707070;
$-color-grey-light: #f6f6f6;
$-color-danger: $-color-red-80;
$-color-warning: $-color-yellow-80;
$-color-success: $-color-green-80;
$-form-focus-border-color: #007dff;
$-body-background-color: $-color-white;
$-color-blue-grey-1: #bbc8d8;
$-color-blue-grey-2: #d0dbe8;
$-color-blue-grey-3: #7b899b;
$-border-color-base: $-color-blue-grey-2;
$-mask-background: rgb(0 0 0 / 50%);

/* Borders
-------------------------- */
$-border-radius-base: 8px;
$-main-padding-value: 20;
$-main-padding: #{$-main-padding-value}px;
$-button-border-radius: 8px;
$-input-border-radius: 8px;

/* Fonts
-------------------------- */
$-line-height-large: 32px;
$-line-height-base: 24px;
$-line-height-small: 20px;
$-line-height-xs: 16px;
$-font-size-xl: 36px;
$-font-size-large: 24px;
$-font-size-base: 18px;
$-font-size-small: 16px;
$-font-size-xs: 12px;
$-font-size-xxs: 10px;

/* Margins & Paddings
-------------------------- */
$-base-margin: 20px;
$-tabs-width: 200px;
$-page-padding: 40px;
$-page-padding-mobile: 15px;
$-tiny-margin: 0.5 * $-base-margin;
$-small-margin: 0.75 * $-base-margin;
$-medium-margin: 1.5 * $-base-margin;
$-large-margin: 2 * $-base-margin;
$-xl-margin: 3 * $-base-margin;
$-xxl-margin: 4 * $-base-margin;
$-input-padding-base: 12px;
$-input-padding-small: 8px;
$-input-border-radius: 12px !default;
$-disabled-background-color: #eaf1f9;

/* Box-shadow
-------------------------- */
$-box-shadow-big: 0 6px 32px 0 $-color-primary-80;
$-box-shadow-small: 0 6px 32px 0 $-color-primary-30;
$-box-shadow-xs: 0 6px 8px 0 $-color-primary-30;
$-box-shadow-inner-top: inset 0 6px 2px $-color-primary-30;
$-box-shadow-inner-bottom: inset 0 -6px 2px $-color-primary-30;

/* Transition
-------------------------- */
$-all-transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1) !default;
$-fade-transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1) !default;
$-fade-linear-transition: opacity 0.2s linear !default;
$-transform-transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !default;
$-size-transition: width 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
  height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !default;
$-border-transition-base: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !default;
$-color-transition-base: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !default;

/* Break-point
-------------------------- */
$-xs: 390px !default;
$-sm: 720px !default;
$-md: 800px !default;
$-lg: 1000px !default;
$-xl: 1320px !default;
$-breakpoints: (
  "xs": (
    min-width: $-xs,
  ),
  "sm": (
    min-width: $-sm,
  ),
  "md": (
    min-width: $-md,
  ),
  "lg": (
    min-width: $-lg,
  ),
  "xl": (
    min-width: $-xl,
  ),
);
$-breakpoints-spec: (
  "xxs": "(max-width: #{$-xs - 0.001})",
  "xs-only": "(min-width: #{$-xs}) and (max-width: #{$-sm - 0.001})",
  "xs-and-down": "(max-width: #{$-sm - 0.001})",
  "sm-and-up": "(min-width: #{$-sm})",
  "sm-only": "(min-width: #{$-sm}) and (max-width: #{$-md - 0.001})",
  "sm-and-down": "(max-width: #{$-md - 0.001})",
  "md-and-up": "(min-width: #{$-md})",
  "md-only": "(min-width: #{$-md}) and (max-width: #{$-lg - 0.001})",
  "md-and-down": "(max-width: #{$-lg - 0.001})",
  "lg-and-up": "(min-width: #{$-lg})",
  "lg-only": "(min-width: #{$-lg}) and (max-width: #{$-xl - 0.001})",
  "lg-and-down": "(max-width: #{$-xl - 0.001})",
  "xl-only": "(min-width: #{$-xl})",
);
