@import "variables";

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: none;
  }
}

@keyframes slide-up-reverse {
  0% {
    transform: none;
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes slide-right {
  0% {
    transform: none;
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes slide-right-reverse {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: none;
  }
}

@keyframes slide-left {
  0% {
    transform: none;
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slide-left-reverse {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: none;
  }
}

@keyframes slide-down {
  0% {
    transform: none;
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes slide-down-reverse {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: none;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-left {
  animation-name: fade-out, slide-left;
  animation-duration: 0;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.fade-left-reverse {
  animation-name: fade, slide-left-reverse;
  animation-duration: 0;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.fade-right {
  animation-name: fade-out, slide-right;
  animation-duration: 0;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.fade-right-reverse {
  animation-name: fade, slide-right-reverse;
  animation-duration: 0;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.skeleton {
  background-color: $-color-grey-light;
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    content: "";
    width: 64px;
    height: 100%;
    animation: skeleton 5s infinite;
    background: linear-gradient(
      90deg,
      $-color-grey-light,
      $-color-white,
      $-color-grey-light
    );
  }
}

@keyframes skeleton {
  0% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(100vw);
  }
}

@keyframes calendar-next-month {
  0% {
    transform: none;
  }

  40% {
    transform: translateX(-100%);
    opacity: 0;
  }

  60% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(48px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
