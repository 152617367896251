@import "variables";
@import "animation";
@import "@angular/cdk/overlay-prebuilt.css";

@font-face {
  font-family: cooper-medium;
  src: url("assets/fonts/Cooper Medium BT.ttf") format("truetype");
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
em,
img,
strong,
sub,
sup,
b,
i,
dl,
dt,
dd,
ol,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
input,
textarea,
select,
article,
aside,
canvas,
details,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
section,
time,
button {
  margin: 0;
  padding: 0;
  border: 0;
  word-break: break-word;
  vertical-align: baseline;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: $-font-size-small;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $-color-black;
  text-transform: uppercase;
  font-weight: 300;
  font-family: "Roboto Condensed", sans-serif;
}

h1 {
  @media screen and #{map_get($-breakpoints-spec, "sm-and-up")} {
    font-size: $-font-size-small * 2;
  }

  @media screen and #{map_get($-breakpoints-spec, "md-and-up")} {
    font-size: $-font-size-base * 2;
  }

  font-size: $-font-size-xs * 2;
}

h2 {
  @media screen and #{map_get($-breakpoints-spec, "sm-and-up")} {
    font-size: $-font-size-small * 1.5;
  }

  @media screen and #{map_get($-breakpoints-spec, "md-and-up")} {
    font-size: $-font-size-base * 1.5;
  }

  font-size: $-font-size-xs * 1.5;
}

h3 {
  @media screen and #{map_get($-breakpoints-spec, "sm-and-up")} {
    font-size: $-font-size-small * 1.2;
  }

  @media screen and #{map_get($-breakpoints-spec, "md-and-up")} {
    font-size: $-font-size-base * 1.2;
  }

  font-size: $-font-size-xs * 1.2;
}

body,
html {
  height: 100%;
  margin: 0;
  color: $-color-black;
  display: block;
  background-color: $-body-background-color;
  -webkit-tap-highlight-color: transparent;
  transition: $-all-transition;
  letter-spacing: 0;
  font-weight: 100;

  @media screen and #{map_get($-breakpoints-spec, "sm-and-up")} {
    font-size: $-font-size-small;
  }

  @media screen and #{map_get($-breakpoints-spec, "md-and-up")} {
    font-size: $-font-size-base;
  }

  font-size: $-font-size-xs;
}

hr {
  border-top: solid 2px $-color-grey;
  padding: 0 8px;

  &.grow {
    flex-grow: 1;
    width: 100%;
  }
}

textarea {
  border: $-header-border-color solid 1px;
  width: 100%;
  height: 160px;
  font-size: 16px;
  outline: none;
  border-radius: $-input-border-radius;
  box-sizing: border-box;
  padding: 8px;
  margin-bottom: 8px;
  resize: none;
}

strong {
  color: $-color-black;
  font-weight: 500;
}

.color-primary {
  background-color: $-color-primary;
  color: white;

  &:not(.no-border) {
    border: 2px solid $-color-primary;
  }

  &.outline {
    background-color: transparent;
    color: $-color-primary;

    &.no-border {
      border: none;
    }

    &.pointer {
      &:hover {
        background-color: $-color-primary-light;
      }
    }
  }
}

.color-inactive {
  background-color: $-color-grey;
  color: white;

  &.outline {
    background-color: transparent;
    border: 1px solid $-color-grey;
    color: $-color-grey;
  }
}

.color-danger {
  color: $-color-danger;
}

.color-white {
  color: $-color-white;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.rotated-180 {
  transform: rotate(180deg);
}

.margin-top {
  margin-top: $-base-margin;

  &-xl {
    margin-top: 2 * $-base-margin;
  }

  &-sm {
    margin-top: 1/2 * $-base-margin;
  }

  &-mobile {
    @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
      margin-top: $-base-margin;
    }
  }
}

.margin-bottom {
  margin-bottom: $-base-margin;

  &-mobile {
    @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
      margin-bottom: $-base-margin;
    }
  }

  &-xl {
    margin-bottom: 2 * $-base-margin !important;
  }
}

.margin-right {
  margin-right: $-base-margin;
}

.margin-left {
  margin-left: $-base-margin !important;
}

.base-padding {
  padding: calc($-base-margin / 2);
}

.base-margin {
  margin: calc($-base-margin / 2);
}

.padding-left-xl {
  padding-left: 2 * $-base-margin;
}

.padding-right-xl {
  padding-right: 2 * $-base-margin;
}

.tiny-margin-top {
  margin-top: calc($-base-margin / 2);
}

.tiny-margin-bottom {
  margin-bottom: calc($-base-margin / 2);
}

.tiny-margin-right {
  margin-right: calc($-base-margin / 2);
}

.tiny-margin-left {
  margin-left: calc($-base-margin / 2);
}

.space-between {
  justify-content: space-between;
}

.box-shadow {
  box-shadow: $-box-shadow-small;
}

.pointer {
  cursor: pointer;

  &.mobile {
    @media screen and #{map_get($-breakpoints-spec, "md-and-up")} {
      cursor: initial;
    }
  }
}

.row {
  display: flex !important;
  flex-direction: row;

  &.gap {
    gap: 16px;
  }
}

.mobile-column {
  display: flex !important;
  flex-direction: row;

  &.gap {
    gap: 16px;
  }

  &-reverse {
    display: flex !important;
    flex-direction: row-reverse;

    &.gap {
      gap: 16px;
    }

    @media screen and #{map-get($-breakpoints-spec, "md-and-down")} {
      flex-direction: column;
    }
  }

  @media screen and #{map-get($-breakpoints-spec, "md-and-down")} {
    flex-direction: column;
  }
}

.mobile-row {
  display: flex !important;
  flex-direction: column;

  &.tiny-gap {
    gap: 8px;
  }

  &.gap {
    gap: 16px;
  }

  @media screen and #{map-get($-breakpoints-spec, "md-and-down")} {
    flex-direction: row;

    &.center {
      justify-content: center;
    }
  }
}

.column {
  display: flex !important;
  flex-direction: column;

  &.gap {
    gap: 16px;
  }
}

.grow {
  display: flex;
  flex-grow: 1;

  &-mobile {
    display: flex;
    flex-grow: 1;
    @media screen and #{map_get($-breakpoints-spec, "lg-and-up")} {
      flex-grow: initial;
    }
  }

  &-desktop {
    display: flex;
    flex-grow: 1;
    @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
      flex-grow: initial;
    }
  }
}

.shrink {
  display: flex;
  flex-shrink: 1;
}

.center-children {
  display: flex;
  justify-content: center;
  align-items: center;

  &-mobile {
    @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-horizontally {
    display: flex;
    justify-content: center;
  }

  &-vertically {
    display: flex;
    align-items: center;
  }
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &.gap {
    gap: 32px;
  }

  &.row-gap {
    row-gap: 32px;
  }

  &.column-gap {
    column-gap: 32px;
  }

  &.start {
    justify-content: start;
  }
}

.text-right {
  text-align: right;
}

/// use with a parent which has width and height and position relative
.center-by-relative-parent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/// use with a parent which has width and height and position relative
.center-vertically-by-relative-parent {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.center-horizontally-by-margin {
  min-width: 32px;
  margin: auto;
}

.center-vertically-by-margin {
  margin: auto 0;
}

a {
  text-decoration: underline;
  color: $-color-black;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    color: $-color-black;
  }

  &.tiny-button {
    border-radius: 16px;
    padding: 8px;
    border: solid 2px $-color-primary;
    background-color: $-color-primary;
    color: $-color-white;

    &:hover {
      border: solid 2px $-color-black;
      background-color: $-color-black;
    }

    &.outline {
      border: solid 2px $-color-primary;
      background-color: transparent;
      color: $-color-primary;

      &:hover {
        background-color: $-color-primary-light;
        border-color: $-color-black;
        color: $-color-black;
      }
    }
  }
}

// no autofill color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 032px white inset !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none;
}

.mask {
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

@supports (height: 100dvh) {
  .mask {
    height: 100dvh;
  }
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  height: 100vh;

  &.mask {
    background-color: $-mask-background;
  }
}

@media (max-width: 768px) {
  @supports (height: 100dvh) {
    .full-screen {
      min-height: 100dvh;
      height: 100dvh;
    }
  }
}

.full-width {
  min-width: 100%;
  width: 100%;
  max-width: 100%;

  &.base-padding {
    min-width: calc(100% - $-base-margin);
    width: calc(100% - $-base-margin);
    max-width: calc(100% - $-base-margin);
  }
}

.half-width {
  width: 49%;

  &-mobile {
    width: 49%;
    @media screen and #{map_get($-breakpoints-spec, "lg-and-up")} {
      width: initial;
    }
  }

  &-desktop {
    width: 49%;
    @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
      width: initial;
    }
  }
}

.full-height {
  height: 100dvh;
  max-height: 100dvh;
  @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
    height: 80dvh;
  }
}

.object-cover {
  object-fit: cover;
}

.hidden {
  display: none;
}

.not-visible {
  visibility: hidden;
}

.mobile-hidden {
  @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
    display: none !important;
  }
}

.desktop-hidden {
  @media screen and #{map_get($-breakpoints-spec, "lg-and-up")} {
    display: none !important;
  }
}

.card {
  padding: $-base-margin;
  background: $-color-white;
  border-radius: $-input-border-radius;

  &.border-left {
    border-radius: 32px 0 032px;
  }

  &.border-right {
    border-radius: 0 32px 32px 0;
  }

  hr {
    padding: -$-base-margin 0;
  }
}

::selection {
  background: $-color-primary;
  color: white;
}

.dialog-backdrop {
  background-color: $-mask-background;
  cursor: auto;
}

.dialog-panel {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: $-color-white;
  padding: 32px;
  max-height: 100dvh;
  overflow-y: auto;
  position: absolute !important;

  @media screen and #{map_get($-breakpoints-spec, "sm-and-down")} {
    max-height: calc(100dvh - 64px);
  }

  &:not(.pop-in) {
    @media screen and #{map_get($-breakpoints-spec, "md-and-up")} {
      right: 0;
      height: 100dvh;
      border-bottom-left-radius: 32px;
      border-top-left-radius: 32px;

      &:not(.closing) {
        animation: slide-right-reverse 0.5s ease-in-out forwards;
      }

      &.closing {
        animation: slide-right 0.5s ease-in-out forwards;
      }
    }

    @media screen and #{map_get($-breakpoints-spec, "sm-and-down")} {
      bottom: 0;
      width: 100vw;
      border-top-right-radius: 32px;
      border-top-left-radius: 32px;
      animation: slide-up 0.5s ease-in-out forwards;

      &:not(.closing) {
        animation: slide-up 0.5s ease-in-out forwards;
      }

      &.closing {
        animation: slide-up-reverse 0.5s ease-in-out forwards;
      }
    }
  }

  &.pop-in {
    border-radius: $-border-radius-base;
    animation: fade ease-in-out 0.5s forwards;
  }
}

.overlay-panel {
  min-width: max-content;
}

.dropdown-content {
  min-width: calc(100% - 16px);
  width: calc(100% - 16px);
  max-width: 100vw;
  padding: 8px;
  background-color: $-color-white;
  box-shadow: $-box-shadow-small;
  border: solid 1px $-color-grey;

  .dropdown-item {
    padding: 8px 16px;
    background-color: $-color-white;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    gap: 8px;

    &:hover {
      background-color: $-color-primary-light;
    }
  }
}

.fading-section-list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  > * {
    opacity: 0;
    animation: ease-out fade-up 0.5s forwards;

    @for $i from 1 through 10 {
      &:nth-of-type(#{ $i }) {
        animation-delay: calc(#{$i} * 0.5s);
      }
    }
  }
}

.tag {
  font-size: 12px;
  padding: 8px;
  width: max-content;
  min-height: 16px;
  background-color: $-color-white;
  border: 1px $-color-black solid;
  border-radius: 16px;
  cursor: pointer;

  &.selected {
    background-color: $-color-primary-light;
    border: 1px $-color-primary-light solid;
  }

  &.primary {
    background-color: $-color-primary;
    border: 1px $-color-primary solid;
    color: $-color-white;
  }

  &.disabled {
    background-color: $-color-grey-light;
    border: 1px $-color-grey-light solid;
  }

  &.hidden-tag {
    opacity: 0;
    cursor: default;
  }
}

.input-with-unit {
  display: flex;
  gap: 16px;

  .value-input {
    width: calc(90% - 176px);
  }

  .unit {
    width: 160px;
    height: auto;

    &:not(app-input) {
      margin-top: 16px;
    }
  }
}

.bubble {
  display: flex;
  gap: 6px;
  padding: 8px 16px;
  background-color: white;
  border: 1px solid $-color-grey;
  border-radius: $-input-border-radius;
  font-size: $-font-size-small;
  color: $-color-black;
  max-width: max-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: max-content;
}

.sized-wrapper {
  padding-left: calc((100% - $-centered-component-max-width) / 2);
  padding-right: calc((100% - $-centered-component-max-width) / 2);
  overflow-y: auto;
  display: flex;
  max-height: 100dvh;
  max-width: 100vw;
  overflow-x: hidden;

  .wrapper {
    width: 100%;
    height: max-content;
    max-height: max-content;
    box-sizing: border-box;
    padding: 20px 24px;

    @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
      padding: 15px;
    }
  }

  @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
    max-height: calc(100dvh - 50px);
    height: calc(100dvh - 50px);
    min-height: calc(100dvh - 50px);
    padding-bottom: 80px;
    padding-left: 0;
    padding-right: 0;
  }
}

.padding-wrapper {
  max-height: calc(100dvh - $-page-padding);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 40px;
  @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
    padding: $-page-padding-mobile;
  }

  &-left {
    padding: 0 $-page-padding;
    @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
      padding: 0 $-page-padding-mobile;
    }
  }
}

.talker-padding-wrapper {
  max-height: calc(100dvh - $-page-padding);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 40px;
  @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
    padding: $-page-padding-mobile;
  }

  &-left {
    padding: 0 $-page-padding;
    @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
      padding: 0 $-page-padding-mobile;
    }
  }
}

.org-in-padding-wrapper-full-width {
  @media screen and #{map_get($-breakpoints-spec, "lg-and-up")} {
    margin-left: 32px;
    width: calc(100vw - $-tabs-width - 32px);
  }

  @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
    margin-left: 32px;
    width: calc(100vw - (2 * $-page-padding-mobile));
  }
}

.in-padding-wrapper-full-width {
  @media screen and #{map_get($-breakpoints-spec, "lg-and-up")} {
    margin-left: -$-page-padding;
    width: calc(100vw - $-tabs-width - 32px);
  }

  @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
    margin-left: -$-page-padding-mobile;
    width: calc(100vw - (2 * $-page-padding-mobile));
  }
}

.text-center {
  text-align: center;
  vertical-align: center;
}

.text-medium {
  font-size: $-font-size-base;
}

.text-large {
  font-size: $-font-size-large;
}

.text-small {
  font-size: $-font-size-xs !important;
}

.text-xl {
  font-size: $-font-size-xl !important;
}

.text-primary {
  color: $-color-primary-80;
}

.text-primary-120 {
  color: $-color-primary-120;
}

.text-secondary {
  color: $-color-secondary-120;
}

.text-secondary-100 {
  color: $-color-secondary-100;
}

.text-black {
  color: $-color-black;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.info-tile {
  background-color: $-color-grey-light;
  border-radius: $-border-radius-base;

  > * {
    padding: 16px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.cooper {
  font-family: cooper-medium, sans-serif;
  text-transform: initial !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    color: $-color-black;
    display: flex;
    align-items: center;
    line-height: 20px;
    padding-bottom: 8px;

    span {
      line-height: 24px;
    }
  }

  li::before {
    content: "\2022"; /* \2022 is the CSS Code/unicode for a bullet */
    font-weight: bold;
    display: inline-block;
    font-size: 30px;
    margin-right: 20px;
  }

  &.better-life {
    li::before {
      color: $-color-yellow-100;
    }
  }

  &.professional-life {
    li::before {
      color: $-color-red-100;
    }
  }

  &.personal-life {
    li::before {
      color: $-color-blue-100;
    }
  }

  &.self-reflection {
    li::before {
      color: $-color-indigo-100;
    }
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

iframe {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.organization-icon {
  font-size: 16px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  background-color: $-color-indigo-100;
  color: $-color-white;

  @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
    width: 24px;
    height: 24px;
    font-size: 8px;
  }
}

.resume-wrapper {
  box-shadow: 1px 3px 6px rgb(39 18 55 /30%);
}   

.resume {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  background-color: $-color-white;

  .tile {
    width: 200px;
    height: 150px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    overflow: hidden;
    margin: auto;

    &-bubble {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: center;
      width: max-content;
      align-items: center;
      padding: 16px;
    }
  }
}

.sticky {
  position: sticky;
  background-color: $-color-white;
  top: 10px;
  padding: $-input-padding-base;
  transform: translateY(-$-page-padding);
  z-index: 1000;
}

.text-bottom {
  align-items: end;
}

.text-bold {
  font-weight: 500;
}

.organizations-list {
  border-top: solid 1px $-color-grey;
}

.organization-item {
  border-bottom: solid 1px $-color-grey;
  padding: 10px 0;
}

@for $i from 0 through 40 {
  .flex-#{$i * 10} {
    flex: 1 1 $i * 10px;
  }
}

@for $i from 0 through 20 {
  .width-#{$i * 50} {
    width: $i * 50px;
  }
}

.bottom {
  margin-top: auto;
}

.right {
  margin-left: auto;
}

.rounded-icon {
  width: 24px;
  height: 24px;
  border: solid 2px $-color-black;
  border-radius: 24px;
  color: $-color-black;
  float: right;
  padding: 4px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
}

.home-wrapper {
  padding: 16px;
  background-color: $-color-grey-light;
}

.notification-resume-wrapper {
  padding: 16px 30px 16px 16px;
  background-color: $-color-grey-light;

  @media screen and #{map_get($-breakpoints-spec, "lg-and-up")} {
    margin-left: -40px;
    width: calc(100vw - $-tabs-width - 46px);
    min-width: calc(100vw - $-tabs-width - 46px);
  }

  @media screen and #{map_get($-breakpoints-spec, "md-and-down")} {
    margin-left: -$-page-padding-mobile;
    width: calc(100vw - (2 * $-page-padding-mobile));
  }
}

.success {
  color: $-color-success;
}

.white {
  color: $-color-white;
}

.warning {
  color: $-color-warning;
}

.danger {
  color: $-color-danger;
}
